export default {
  colors: {
    text: '#333',
    background: '#eee',
    primary: '#95e4c8',
    secondary: '#fcf391',
    accent: '#db456f',
    highlight: '#a74faf',
    muted: 'rgba(0, 0, 0, .75)',
    success: '#cbf4c9',
    warning: '#e3e8ee',
    error: '#fde2dd',
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Lemon Milk Pro, sans-serif',
  },
  fontWeights: {
    light: 300,
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  radii: {
    sm: '.25em',
    md: '.5em',
    lg: '1em',
  },
  sizes: {
    container: 1200,
  },
  shadows: {
    sm: `0 .25rem 1rem rgba(0, 0, 0, .125)`,
    md: '0 .5rem 1rem rgba(0, 0, 0, .25)',
    lg: '0 1rem 2rem rgba(0, 0, 0, .25)',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      mb: 0,
    },
  },
  overlay: {
    position: 'relative',
    '::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background:
        'linear-gradient(rgba(0, 0, 0, .5) 20%, rgba(0, 0, 0, 0) 75%)',
      zIndex: 10,
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
      borderColor: 'primary',
      borderRadius: 'sm',
      borderStyle: 'solid',
      borderWidth: 3,
      display: 'inline-block',
      fontSize: 'inherit',
      fontWeight: 'bold',
      lineHeight: 'inherit',
      m: 0,
      mt: 4,
      mx: 'auto',
      px: 3,
      py: 1,
      textAlign: 'center',
      textDecoration: 'none',
      transition: 'all .25s',
      '&:hover': {
        bg: 'transparent',
        color: 'primary',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'secondary',
      borderColor: 'secondary',
      '&:hover': {
        bg: 'transparent',
        color: 'secondary',
      },
    },
    accent: {
      variant: 'buttons.primary',
      bg: 'accent',
      borderColor: 'accent',
      '&:hover': {
        bg: 'transparent',
        color: 'accent',
      },
    },
    outline: {
      primary: {
        variant: 'buttons.primary',
        bg: 'transparent',
        borderColor: 'primary',
        color: 'primary',
        '&:hover': {
          bg: 'primary',
          color: 'white',
        },
      },
      secondary: {
        variant: 'buttons.outline.primary',
        borderColor: 'secondary',
        color: 'secondary',
        '&:hover': {
          bg: 'secondary',
          color: 'white',
        },
      },
      accent: {
        variant: 'buttons.outline.primary',
        borderColor: 'accent',
        color: 'accent',
        '&:hover': {
          bg: 'accent',
          color: 'white',
        },
      },
      text: {
        variant: 'buttons.outline.primary',
        borderColor: 'text',
        color: 'text',
        '&:hover': {
          bg: 'text',
          color: 'white',
        },
      },
      white: {
        variant: 'buttons.outline.primary',
        borderColor: 'white',
        color: 'white',
        '&:hover': {
          bg: 'white',
          color: 'text',
        },
      },
    },
  },
  visuallyHidden: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'text',
      textDecoration: 'none',
      transition: 'color .25s',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [6, 7],
      mt: 6,
    },
    h2: {
      variant: 'text.heading',
      fontSize: [5, 6],
      mt: 5,
    },
    h3: {
      variant: 'text.heading',
      fontSize: [4, 5],
      mt: 4,
    },
    h4: {
      variant: 'text.heading',
      fontSize: [3, 4],
    },
    h5: {
      variant: 'text.heading',
      fontSize: [2, 3],
    },
    p: {
      mb: 0,
      a: {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
}
